import * as React from "react"

import Seo from "../components/seo"

const LegalNotices = () => (
  <>
    <Seo title="Mentions Légales" />

  </>
)

export default LegalNotices
